<template>
  <div class="home">
    <ManualFights :farmers="farmers" :requestFarmers="this.update"></ManualFights>
    <AutoFights :farmers="farmers" :requestFarmers="this.update"></AutoFights>
  </div>
</template>

<script>

import store from "@/store";
import Vue from 'vue';
import ManualFights from "@/components/ManualFights.vue";
import AutoFights from "@/components/AutoFights.vue";

export default {
  components: {
    ManualFights,
    AutoFights
  },
  data() {
    return {
      user: store.getters.getUser,
      farmers: [],
      unsubFarmers: null,
      selectedFarmer: null,
      selectedLeek: null,
      fightCount: 0
    }
  },
  async mounted() {
    this.getFullFarmers({uid: this.user.uid}).then((res) => {
      console.log(res);
      this.farmers = res.data.farmers;
    })
  },
  methods: {
    update() {
      this.getFullFarmers({uid: this.user.uid}).then((res) => {
        this.farmers = res.data.farmers;
      });  
    },
    getFullFarmers: Vue.firebase.functions.getFullFarmers
  }
}

/**
 * 
 * LW_MANUAL
 * login: accountLogin
 * type: LeekId (INT) / None
 * fightCount: INT
 * status: 0 (Waiting), (1 Done), (2 Error)
 * fightDone: [] array of Fight
 *
**/


</script>

<style>
ul li {
  text-align: left;
}

</style>
